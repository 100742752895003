import styled from "styled-components";
import tw from 'twin.macro';

export const Content = styled.div``;

export const Wrapper = styled.div`
  margin-top:27px;
  .ptop{
    margin-top: 49px;
  }
  a{
    color: #0963CD;
  }
  .content{
    ${tw`flex justify-between`};
    .miniCartPayment{
    }
    .cardPayment{
      ${tw`mt-7`};
    }
  }
  @media (max-width: 640px) {
    ${tw`mt-16`};
  }
  @media (max-width: 990px) {
    .content {
      padding: 0 1.5rem;
    }
  }
`;

export const Wrap = styled.div`
  ${tw`xl:w-9/12 m-auto sm:flex justify-center items-center mb-12`};
  .wrapCard{
    #err_card_holder, #err_number,
    #err_month, #err_cvv{
      margin-top: 4px;
    }
  }
  @media (max-width: 1640px) {
    ${tw`w-full`};
  }
`;

export const Margin = styled.div`
  ${tw`mt-6 sm:mt-0`};
`;

export const Grid = styled.div`
  ${tw`md:grid md:grid-cols-2 md:gap-6`};
`;

export const ColSpan = styled.div`
  ${tw`md:col-span-1 mx-auto`};
  .cartWrap{
    margin-top: 49px;
    ${tw`w-full px-4 space-y-6 sm:px-6`};
  }
`;

export const WrapSummary = styled.div`
  width: 448px;
  margin-top: 49px;
  ${tw`px-4 space-y-6 sm:px-6 px-2`};
  @media (max-width: 1024px) {
    ${tw`w-full`};
  }
`;

export const SummaryTitle = styled.div`
  color: #9A9292;
  letter-spacing: 0.08em;
  margin-top: 39px;
  ${tw`text-mini11 pb-1`}
`;

export const FlexStart = styled.div`
  margin: 11px 0;
  ${tw`flex items-start`};
  div{
    ${tw`text-sm w-full`};
    label{
      letter-spacing: normal;
      ${tw`text-xs font-medium text-brown-darker`};
    }
    p{
      letter-spacing: 0.5px;
      ${tw`text-xssemi text-brown-darker`};
    }
  }
`;

export const CartTotal = styled.div`
  ${tw`px-2 py-6 border-t border-b border-gray-200`};
  div{
    ${tw`flex justify-between`};
    p{
      color: #9B9999;
      letter-spacing: 0.05em;
      ${tw`text-xs`};
      :nth-child(2){
        ${tw`text-brown-darker`};
      }
    }
  }
`;

export const TotalPayment = styled.div`
  ${tw`col-span-3 sm:col-span-2  p-2`};
  div{
    ${tw`flex justify-between`};
    p{
      line-height: 25px;
      letter-spacing:normal;
      ${tw`text-lg text-brown-darker`};
      :nth-child(2){
        color: #9B9999;
        letter-spacing: 0.05em;
        ${tw`text-xs`};
      }
    }
    .gst{
      color: #9B9999;
      letter-spacing: 0.05em;
      ${tw`text-xs`};
    }
  }
`;

export const Checkout = styled.div`
  ${tw`py-3`};
  button{
    color: #707070;
    letter-spacing: 0.05em;
    ${tw`w-full inline-flex justify-center py-2 px-4 text-base bg-gray-200 hover:bg-black hover:text-white`};
  }
  p{
    letter-spacing: 0.05em;
    color: #9B9999;
    ${tw`text-xs mt-4 text-center`};
  }
`;

export const PayNow = styled.button`
  background-color: black !important;
  color: white !important;
`;

export const RightGrid = styled.div`
  ${tw`col-span-3 sm:col-span-2  p-2 h-80`};
`;

export const Button = styled.button`
  ${tw`w-full p-1 ml-auto border-0 text-black text-xl3 leading-none font-semibold outline-none focus:outline-none`};
  svg{${tw`float-right`};}
`;

export const Desktop = styled.div`
  ${tw`w-full hidden sm:block`}
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`}
  .header{
    ${tw`text-center mb-8 mt-4 px-12`};
    h1{
      letter-spacing: 0.04em;
      ${tw`text-grayu-basic text-xl3 font-bold`};
    }
    p{ ${tw`text-sm text-brown-darker font-medium`}; }
  }
`;

export const Coupon = styled.div`
  display: flex !important;
  gap: 1rem;

  .coupon_code {
    flex: 1;
  }

  span.label {
    flex: 1;
    font-size: 12px;
    font-weight: bold;
  }

  .applyCouponField {

  }

  .applyCouponBtn {
    border: 1px solid #000;
    padding: 0 1rem;
    height: 40px;
    margin-top: 24px;
  }
`;
