import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import PropTypes from "prop-types";
import { format, parseISO, setMonth, setYear, startOfMonth } from "date-fns";
import { graphql, useStaticQuery } from "gatsby";

import * as Styled from './styles';
import Minicart from 'components/Minicart/PaymentSummary';
import NewCard from './NewCard';
import PaymentMethod from './PaymentMethod';
import { selectCheckoutAddress$, selectCheckoutCard$, selectUser$, } from '../../state/user/user.service';
import { processCheckout, selectCheckout$, setCheckoutCardId } from '../../state/checkout/checkout.service';
import { applyCoupon, selectCart$ } from '../../state/cart/cart.service';
import { createPaymentCard } from '../../state/user/payment-card.service';
import Wallet from '../Account/wallet';
import GoBackLink from './../ui/GoBackLink'
import Danger from "../ui/Alert/Danger";
import { useI18next } from "gatsby-plugin-react-i18next";
import CloseIcon from './../ui/icons/close'
import { Form, Formik } from 'formik';
import { interpolateTranslation } from "../../state/utils";
import Warn from "../../assets/images/warn-gray.png";

const Payment = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiPaymentPage_en: strapiPaymentPage(locale: {eq: "en"}) {
        back_to_delivery_details
        payment_mobile_heading
        payment_mobile_subheading
        payment_summary
        deliver_to
        date_time_of_delivery
        special_instructions
        cart_total
        delivery_fee
        total_payment
        all_currencies_in_aud
        pay_now
        credit_card_info_label
        credit_card_account_name
        credit_card_number
        credit_card_expiry_date
        credit_card_cvv
        gst_included_in_total
        inc_gst
        coupon_code_label
        coupon_code_placeholder
        apply_coupon_button_label
      }
      strapiPaymentPage_zh: strapiPaymentPage(locale: {eq: "zh"}) {
        back_to_delivery_details
        payment_mobile_heading
        payment_mobile_subheading
        payment_summary
        deliver_to
        date_time_of_delivery
        special_instructions
        cart_total
        delivery_fee
        total_payment
        all_currencies_in_aud
        pay_now
        credit_card_info_label
        credit_card_account_name
        credit_card_number
        credit_card_expiry_date
        credit_card_cvv
        gst_included_in_total
        inc_gst
        coupon_code_label
        coupon_code_placeholder
        apply_coupon_button_label
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: {eq: "en"}) {
        email_address_required
        invalid_email_address
        invalid_password
        invalid_login_credentials
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        invalid_number_error
        invalid_cvv_error
        invalid_card_type_error
        cc_payment_failed
        coupon_code_required
        coupon_already_used
        coupon_not_available
        coupon_cannot_be_applied
        coupon_min_total_not_reached
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: {eq: "zh"}) {
        email_address_required
        invalid_email_address
        invalid_password
        invalid_login_credentials
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        invalid_number_error
        invalid_cvv_error
        invalid_card_type_error
        cc_payment_failed
        coupon_code_required
        coupon_already_used
        coupon_not_available
        coupon_cannot_be_applied
        coupon_min_total_not_reached
      }
      strapiMinicartWidget_en: strapiMinicartWidget(locale: {eq: "en"}) {
        small_order_fee
        small_order_fee_tooltip
      }
      strapiMinicartWidget_zh: strapiMinicartWidget(locale: {eq: "zh"}) {
        small_order_fee
        small_order_fee_tooltip
      }
    }
  `);

  const translations = gql[`strapiPaymentPage_${ locale }`];
  const errTranslations = gql[`strapiErrorMessages_${ locale }`];
  const translationsMiniCart = gql[`strapiMinicartWidget_${locale}`];
  const [errors, setErrors] = useState([]);

  const [unMount$] = useState(new Subject());
  const [cart, setCart] = useState({});
  const [smallDelFee, setSmallDelFee] = useState(0);
  const [payBtnLabel, setPayBtnLabel] = useState(translations.pay_now);
  const [selectedCheckoutAddress, setSelectedCheckoutAddress] = useState(null);
  const [selectedCheckoutCard, setSelectedCheckoutCard] = useState(null);
  const [paymentJs, setPaymentJs] = useState(null);
  const [hasCardOnFile, setHasCardOnFile] = useState(false);
  const [isCardListModalShown, setIsCardListModalShown] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [couponValue, setCouponValue] = useState(0);
  const [couponObject, setCouponObject] = useState(null);
  const [couponError, setCouponError] = useState(null);
  const [cardInputErrors, setCardInputErrors] = useState([]);
  const { navigate } = useI18next();

  useEffect(() => {
    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      if (!user.id) {
        // navigate('/shop/');
      } else {
        setHasCardOnFile(user && user.payment_cards && !!user.payment_cards.length);
        selectCheckoutAddress$.pipe(takeUntil(unMount$)).subscribe((checkoutAddress) => {
          setSelectedCheckoutAddress(checkoutAddress);
        });
        selectCheckoutCard$.pipe(takeUntil(unMount$)).subscribe((cc) => {
          setSelectedCheckoutCard(cc);
        });
        selectCart$.pipe(takeUntil(unMount$)).subscribe((cart) => {
          setCart(cart);
          const subtotal = parseFloat(cart.subtotal);
          const minCartTotal = parseFloat(cart.min_cart_total);
          if (minCartTotal > subtotal) {
            setSmallDelFee((minCartTotal - subtotal).toFixed(2))
          } else {
            setSmallDelFee(0);
          }
        });
      }
    });

    selectCheckout$.pipe(takeUntil(unMount$)).subscribe((data) => {
      setCheckoutData(data);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  useEffect(() => {
    if (paymentJs && !paymentJs.initialized) {
      paymentJs.init(
        process.env.GATSBY_USTORE_PAYMENTJS_INTEGRATION_KEY,
        'cardNumber',
        'CVV',
        function (util) {
          util.setNumberStyle({
            border: '1px solid #D6D6D6',
            width: '100%',
            height: '100%',
            padding: '0.5rem 0.675rem',
            color: '#383C3F',
            "font-size": '12px'
          });
          util.setCvvStyle({
            border: '1px solid #d1d5db',
            width: '100%',
            height: '100%',
            padding: '0.5rem 0.675rem',
            color: '#383C3F',
            "font-size": '12px'
          });
          util.numberOn('input', function (data) {
            validateCardNumberInputs(data)
            if (data.numberLength === 0) {
              document.getElementById('err_number').innerHTML = locale === 'en' ?
                '<div class="error">Card number is required.</div>' : '<div class="error">必填项目</div>';
            } else if (!data.validNumber && data.numberLength > 4) {
              document.getElementById('err_number').innerHTML = locale === 'en' ?
                '<div class="error">'+errTranslations.invalid_number_error+'</div>' : '<div class="error">必填项目</div>';
            } else {
              document.getElementById('err_number').innerHTML = '';
            }
          });
          util.cvvOn('input', function (data) {
            if (data.cvvLength === 0) {
              document.getElementById('err_cvv').innerHTML = locale === 'en' ?
                '<div class="error">CVV is required.</div>' : '<div class="error">必填项目</div>';
            } else if (!data.validCvv && data.cvvLength > 0 ) {
              document.getElementById('err_cvv').innerHTML = locale === 'en' ?
                '<div class="error">'+errTranslations.invalid_cvv_error+'</div>' : '<div class="error">必填项目</div>';
            } else {
              document.getElementById('err_cvv').innerHTML = '';
            }
          });
        }
      );
    } else {
      // eslint-disable-next-line
      setPaymentJs(new PaymentJs());
    }
  }, [paymentJs, hasCardOnFile]);
  const interceptSubmit = async () => {
    setErrors([]);
    setPayBtnLabel(locale === 'en' ? 'Please Wait' : '请稍等');
    let postData = {
      address_id: selectedCheckoutAddress.id,
      payment_card_id: null,
      delivery_due_date: checkoutData.delivery_due_date,
      accepted_by: checkoutData.accepted_by,
      delivery_location_id: checkoutData.postcode.id,
      special_instructions: checkoutData.special_instructions,
      coupon: couponObject
    };
    if (hasCardOnFile && selectedCheckoutCard) {
      postData.payment_card_id = selectedCheckoutCard.id;
      const response = await processCheckout(postData).toPromise()
        .catch(async (ajax) => {
          setPayBtnLabel('Pay Now')
          switch (ajax.status) {
            case 500:
              setErrors([errTranslations.server_error_500]);
              break;
            case 502:
              setErrors([errTranslations.server_error_502]);
              break;
            case 400:
              setErrors([errTranslations.cc_payment_failed]);
              break;
            case 401:
              setErrors([errTranslations.invalid_login_credentials]);
              break;
            case 422:
              setErrors([errTranslations.cc_payment_failed]);
              if (ajax?.response?.message === 'Transaction Declined') {
                await navigate(`/transaction-declined`);
              }
              break;
            default:
              setErrors([errTranslations.unknown_error]);
              break;
          }
        });

      if (response) {
        await navigate(`/thank-you/?order_number=${ response.order_number }`);
      }

    } else {
      const data = {
        card_holder: document.querySelector('#cardName').value,
        month: document.querySelector('#expiryDate').value.split('/')[0],
        year: document.querySelector('#expiryDate').value.split('/')[1],
      };

      paymentJs.tokenize(
        data,
        async function (token) {
          const newCard = await createPaymentCard({
            transactionToken: token,
          })
            .toPromise()
            .catch((ajax) => {
              setPayBtnLabel('Pay Now')
              switch (ajax.status) {
                case 500:
                  setErrors([errTranslations.server_error_500]);
                  break;
                case 502:
                  setErrors([errTranslations.server_error_502]);
                  break;
                case 400:
                  setErrors([errTranslations.cc_payment_failed]);
                  break;
                case 401:
                  setErrors([errTranslations.invalid_login_credentials]);
                  break;
                case 422:
                  setErrors([errTranslations.cc_payment_failed]);
                  break;
                default:
                  setErrors([errTranslations.unknown_error]);
                  break;
              }
            });

          if (newCard) {
            setCheckoutCardId(newCard.id);
            postData.payment_card_id = newCard.id;
            const response = await processCheckout(postData).toPromise()
              .catch((ajax) => {
                setPayBtnLabel('Pay Now')
                switch (ajax.status) {
                  case 500:
                    setErrors([errTranslations.server_error_500]);
                    break;
                  case 502:
                    setErrors([errTranslations.server_error_502]);
                    break;
                  case 400:
                    setErrors([errTranslations.cc_payment_failed]);
                    break;
                  case 401:
                    setErrors([errTranslations.invalid_login_credentials]);
                    break;
                  case 422:
                    setErrors([errTranslations.cc_payment_failed]);
                    break;
                  default:
                    setErrors([errTranslations.unknown_error]);
                    break;
                }
              });

            if (response) {
              await navigate(`/thank-you/?order_number=${ response.order_number }`);
            }
          }
        },
        function (errors) {
          setPayBtnLabel('Pay Now')
        }
      );
    }
  };

  const formatDate = (date) => {
    if (!date) {
      return ''
    }

    try {
      if (typeof date === 'object') {
        return format(date, 'PPpp')
      }

      if (typeof date === 'string') {
        return format(parseISO(date), 'PPpp')
      }
      return format(date, 'PPpp')
    } catch (e) {
      return format(parseISO(date), 'PPpp')
    }
  }

  const emptyCart = () => {
    document.getElementById('errorCart').innerHTML = locale === 'en' ?
      '<div class="error">Cart is empty!</div>' : '<div class="error">购物车是空的</div>';
  }

  const doApplyCoupon = async (e) => {
    setCouponError(null);
    e.preventDefault();

    await applyCoupon(couponCode).pipe(map(({ response }) => {

      if (response.coupon && !response.error && response.coupon.value > 0) {
        setCouponObject(response.coupon);
        if (response.coupon.type === 'percentage') {
          setCouponValue(cart.subtotal * (response.coupon.value / 100))
        } else {
          setCouponValue(response.coupon.value);
        }
      } else if (!!response.error) {
        switch (response.error.code) {
          case 'coupon_not_found':
            setCouponError([errTranslations.coupon_not_available]);
            break;

          case 'coupon_already_used':
            setCouponError([errTranslations.coupon_already_used]);
            break;

          case 'coupon_minimum_not_reached':
            setCouponError([interpolateTranslation(errTranslations.coupon_min_total_not_reached, [`CART_MIN_TOKEN`], [`$${response.coupon.min_spend}`])]);
            break;

          case 'coupon_expired':
            setCouponError([errTranslations.coupon_cannot_be_applied]);
            break;
          default:
            setCouponError([errTranslations.coupon_cannot_be_applied]);
            break;
        }
      }
      return response;
    })).toPromise()
      .catch((ajax) => {
        switch (ajax.status) {
          case 500:
            setCouponError([errTranslations.server_error_500]);
            break;
          case 502:
            setCouponError([errTranslations.server_error_502]);
            break;
          case 400:
          case 422:
            setCouponError([errTranslations.coupon_code_required]);
            break;
          default:
            setCouponError([errTranslations.unknown_error]);
            break;
        }
      });
  }

  const validateCardNumberInputs = (data) => {
    setCardInputErrors([]);

    if (!['visa', 'mastercard'].includes(data.cardType) && data.numberLength > 4) {
      setCardInputErrors([
        ...cardInputErrors,
        errTranslations.invalid_card_type_error
      ])
    }
  }

  const validate = (values) => {
    if (hasCardOnFile) return true;

    const errors = {};
    let cardDate = new Date();
    let expiredDate = false;
    let invalidDate = false;
    try {
      const cardDateValues = {
        month: document.querySelector('#expiryDate').value.split('/')[0],
        year: document.querySelector('#expiryDate').value.split('/')[1],
      };

      cardDate = setYear(cardDate, '20' + cardDateValues.year);
      cardDate = setMonth(cardDate, parseInt(cardDateValues.month) - 1);

      if (cardDate < startOfMonth(new Date())) {
        expiredDate = true;
      }

      if (cardDate.month > 12) {
        invalidDate = true;
      }

    } catch (e) {
      expiredDate = false;
      invalidDate = false;
    }

    if (!values.cardName) {
      errors.cardName = locale === 'en' ? 'Card name is required.' : '卡名是必填项';
    }

    if (!values.expiryDate) {
      errors.expiryDate = locale === 'en' ? 'Expiry date is required' : '到期日为必填项';
    } else if (/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(values.expiryDate) === false) {
      errors.expiryDate = locale === 'en' ? 'Invalid Format' : '无效的格式';
    } else if (invalidDate) {
      errors.expiryDate = locale === 'en' ? 'Invalid Date' : '失效日期';
    } else if (expiredDate) {
      errors.expiryDate = locale === 'en' ? 'Expired Date' : '过期日期';
    }

    return errors;
  };

  const GST = parseFloat(cart.subtotal / 11);
  const totalWithGST = parseFloat(cart.subtotal / 1.1);
  const overallTotal = parseFloat(totalWithGST + GST);
  const overallTotalWithDelivery = parseFloat(overallTotal + +checkoutData?.postcode?.delivery_fee);

  return (
    <>
      <div className='boxed'>
        <Styled.Wrapper>
          <Styled.Mobile>
            <div className='header'>
              <h1>{ translations.payment_mobile_heading }</h1>
              <p>{ translations.payment_mobile_subheading }</p>
            </div>
          </Styled.Mobile>

          <div className="content">
            <Styled.Desktop>
              <GoBackLink title={ translations.back_to_delivery_details } link='/delivery'/>
            </Styled.Desktop>
          </div>

          <div className="content">
            <div className="miniCartPayment">
              <div className='ptop'>
                <Minicart locale={ locale }/>
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={ {
                cardName: '',
                cardNumber: '',
                expiryDate: '',
                cardCVV: '',
              } }
              validate={validate}
              onSubmit={ async () => {
                if (cardInputErrors.length === 0) {
                  await interceptSubmit();
                }
              } }
            >
              { (formikProps) => {
                return (
                  <Form className="cardPayment">
                    <Styled.SummaryTitle>{ translations.payment_summary }</Styled.SummaryTitle>

                    { hasCardOnFile && <hr/> }
                    { !hasCardOnFile && <NewCard locale={ locale } formikProps={formikProps}/> }

                    { cardInputErrors.map(error => <Danger message={ error }/>) }

                    <Styled.FlexStart>
                      <div>
                        <label htmlFor="comments">
                          { translations.deliver_to }:
                        </label>
                        <p>
                          { [
                            selectedCheckoutAddress?.address_line_1,
                            selectedCheckoutAddress?.address_line_2,
                            selectedCheckoutAddress?.city,
                            selectedCheckoutAddress?.state,
                            selectedCheckoutAddress?.postal_code,
                          ].join(' ') }
                        </p>
                      </div>
                    </Styled.FlexStart>

                    <Styled.FlexStart>
                      <div>
                        <label htmlFor="comments">
                          { translations.date_time_of_delivery }:
                        </label>
                        { !!checkoutData?.delivery_due_date && (<p>{ formatDate(checkoutData.delivery_due_date) }</p>) }
                      </div>
                    </Styled.FlexStart>

                    <Styled.FlexStart>
                      <div>
                        <label htmlFor="comments">
                          { translations.special_instructions }
                        </label>
                        { !!checkoutData?.special_instructions && (<p>{ checkoutData.special_instructions }</p>) }
                      </div>
                    </Styled.FlexStart>

                    { hasCardOnFile && (
                      <PaymentMethod
                        locale={ locale }
                        checkoutCard={ selectedCheckoutCard }
                        chooseCheckoutCard={ () => setIsCardListModalShown(true) }
                      />
                    ) }

                    <Styled.CartTotal>
                      <div>
                        <p>{ translations.cart_total }</p>
                        <p className="m-0">({translations.inc_gst})${ parseFloat(overallTotal).toFixed(2) }</p>
                      </div>

                      <div>
                        <p>{ translations.delivery_fee }</p>
                        <p className="m-0">${ checkoutData?.postcode?.delivery_fee }</p>
                      </div>

                      {!!smallDelFee && (
                        <div>
                          <p>{translationsMiniCart.small_order_fee}</p>
                          <p className="m-0 flex items-center"><img src={Warn} alt="small order fee tooltip" className={"mr-1"} data-tip={interpolateTranslation(translationsMiniCart.small_order_fee_tooltip, [`MIN_CART_TOTAL_TOKEN`], [`${cart.min_cart_total}`])}/>${smallDelFee}</p>
                          <ReactTooltip effect="solid" place="bottom" type="dark"/>
                        </div>
                      )}

                      {couponObject && (
                        <div>
                          <p>Coupon Discount { couponObject.code }</p>
                          <p className="m-0">-${ parseFloat(couponValue).toFixed(2) }</p>
                        </div>
                      )}

                      { couponError && (
                        <div className={"mb-6 mt-2 flex"}>
                          <Danger message={ couponError } extraClassNames={"flex-1"}/>
                        </div>
                      )}
                      { !couponObject && (
                        <Styled.Coupon>
                          <label htmlFor="coupon_code" className={"coupon_code"}>
                            <span className={"label"}>{translations.coupon_code_label}</span>
                            <input type="text" placeholder={translations.coupon_code_placeholder} id={"coupon_code"} className={"applyCouponField"}
                                   value={couponCode}
                                   onChange={(e) => {
                                     setCouponCode(e.target.value);
                                   }}
                            />
                          </label>
                          <button onClick={doApplyCoupon} className={"applyCouponBtn"}>{translations.apply_coupon_button_label}</button>
                        </Styled.Coupon>
                      )}
                    </Styled.CartTotal>

                    <Styled.TotalPayment>
                      <div>
                        <p>{ translations.total_payment }</p>
                        <span className="m-0">${ parseFloat((parseFloat(overallTotalWithDelivery) + parseFloat(smallDelFee)) - couponValue).toFixed(2) }</span>
                      </div>
                      <div>
                        <p className='gst'>{ translations.gst_included_in_total }</p>
                        <p className="m-0">${ parseFloat(GST).toFixed(2) }</p>
                      </div>
                    </Styled.TotalPayment>

                    { cart && cart.products && cart.products.length > 0 && cardInputErrors.length < 1 && (
                      <Styled.Checkout>
                        { cart.subtotal === 0 && (
                          <>
                            <button type="submit" onClick={ emptyCart }>
                              { payBtnLabel }
                            </button>
                            <div id='errorCart' className='mt-1'></div>
                          </>
                        )}

                        { !selectedCheckoutCard && hasCardOnFile && (
                          <p>Please Select A Payment Method</p>
                        )}

                        { !hasCardOnFile &&
                          <Styled.PayNow type="submit">
                            { payBtnLabel }
                          </Styled.PayNow>
                        }

                        { cart.subtotal > 0 &&  selectedCheckoutCard &&
                          <Styled.PayNow type="submit">
                            { payBtnLabel }
                          </Styled.PayNow>
                        }
                        <p>
                          { translations.all_currencies_in_aud }
                        </p>
                        { errors.map((err) => <Danger message={ err }/>) }
                      </Styled.Checkout>
                    ) }

                    { cart && cart.products && cart.products.length < 1 && (
                      <button type="button" disabled className="disabledBtn">
                        { locale === 'en' ? 'Cart is empty' : '购物车是空的' }
                      </button>
                    ) }
                    <Styled.Mobile>
                      <p className='w-full mx-auto text-center'>
                        <GoBackLink title={ translations.back_to_delivery_details } link='/delivery'/>
                      </p>
                    </Styled.Mobile>

                  </Form>
                )
              } }
            </Formik>
          </div>
        </Styled.Wrapper>
      </div>


      <Modal isOpen={ isCardListModalShown } contentLabel="My Payment Cards" id="newCardModal">
        <Styled.Content>
          <Styled.RightGrid>
            <Styled.Button onClick={ () => setIsCardListModalShown(false) }>
              <CloseIcon/>
            </Styled.Button>
            <div className="block">
              <Wallet
                selectForPayment={ true }
                setCardCallback={ (card) => {
                  setSelectedCheckoutCard(card);
                  setIsCardListModalShown(false);
                } }
                locale={locale}
              />
            </div>
          </Styled.RightGrid>
        </Styled.Content>
      </Modal>
    </>
  );
};

Payment.propTypes = {
  locale: PropTypes.string,
};

Payment.defaultProps = {
  locale: 'en',
};

export default Payment;
