import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import * as Styled from './../styles';
import * as Pay from './styles';
import { useObservable } from '@libreact/use-observable';
import { removeItem, selectCart$ } from '../../../state/cart/cart.service';
import { interpolateTranslation, productImageUrl } from "../../../state/utils";

const PaymentSummary = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiPaymentSummaryWidget_en: strapiPaymentSummaryWidget(locale: {eq: "en"}) {
        order_summary
        no_item_in_cart
        back_to_shop_link_text
        your_orders_count
        remove_item
        item_quantity
      }
      strapiPaymentSummaryWidget_zh: strapiPaymentSummaryWidget(locale: {eq: "zh"}) {
        order_summary
        no_item_in_cart
        back_to_shop_link_text
        your_orders_count
        remove_item
        item_quantity
      }
    }
  `);
  const translations = gql[`strapiPaymentSummaryWidget_${locale}`];
  const [cart] = useObservable(selectCart$);
  const items = cart.products;
  const totalUniqueItems = cart.products?.length || 0;
  const isEmpty = totalUniqueItems === 0;

  if (isEmpty)
    return (
      <>
        <Styled.Title>{translations.order_summary}</Styled.Title>
        <Styled.CartWrap>
          <Styled.EmptyCartBlock>
            {translations.no_item_in_cart}
            <br/>
            <Link to="/shop">[ {translations.back_to_shop_link_text} ]</Link>
          </Styled.EmptyCartBlock>
        </Styled.CartWrap>
      </>
    );

  return (
    <div>
      <Pay.Title>{interpolateTranslation(translations.your_orders_count, [`TOTAL_COUNT`], [`${totalUniqueItems}`])}</Pay.Title>
      <Pay.Full>
        <Pay.FlexCol>
          <Pay.FlowRoot>
            <Pay.Ul>
              { items.map((item) => (
                <li key={ item.id } >
                  <Pay.ImageWrap>
                    <img
                      src={
                        item.img_path === null
                          ? `https://via.placeholder.com/254x320/fff/333`
                          : productImageUrl(item.img_path)
                      }
                      alt={ item.name }
                    />
                  </Pay.ImageWrap>

                  <Pay.ProductWrap>
                    <div>
                      <Pay.ProductDetails>
                        <h3>{locale === 'en' ? item.product_name : item.product_name_cn} {locale === 'en' ? item.each_uom : item.each_uom_cn}</h3>
                        <p>
                          <button type="button" onClick={ () => removeItem(item.id) } >
                            [ {translations.remove_item} ]
                          </button>
                        </p>
                      </Pay.ProductDetails>
                      <Pay.ProductQty>
                        <p>{ item.qty } x {locale === 'en' ? item.each_uom : item.each_uom_cn}</p>
                        <p>${ item.price_total }</p>
                      </Pay.ProductQty>
                    </div>
                  </Pay.ProductWrap>
                </li>
              )) }
            </Pay.Ul>
          </Pay.FlowRoot>
        </Pay.FlexCol>
      </Pay.Full>
    </div>
  );
};

PaymentSummary.propTypes = {
  locale: PropTypes.string,
};

PaymentSummary.defaultProps = {
  locale: 'en',
};

export default PaymentSummary;
