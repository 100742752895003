import styled from 'styled-components';
import tw from 'twin.macro';

export const Title = styled.p`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.08em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #9A9292;
  text-transform: uppercase;
`;

export const Full = styled.div`
  ${tw`w-full`}
`;

export const FlexCol = styled.div`
  ${tw`flex flex-col`}
`;

export const FlowRoot = styled.div`
  ${tw`flow-root mt-8`}
`;

export const Ul = styled.ul`
  ${tw`-my-6`}
  min-height: 10vh;
  max-height: 40vh;
  ${tw`overflow-y-auto`}
  li{
    ${tw`flex`}
    background: #FCFCFC;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 4px;
  }
`;

export const ImageWrap = styled.div`
  ${tw`flex-shrink-0 w-14 h-14 border border-gray-200 rounded-md overflow-hidden`}
  img{
    ${tw`object-center object-contain`}
    width: 56px;
    height: 56px;
  }
`;

export const ProductWrap = styled.div`
  ${tw`flex-1 flex flex-col`}
  margin-left: 12px;
`;

export const ProductDetails = styled.div`
  ${tw`sm:flex justify-between text-xs text-gray-900`}
  h3{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #4D4545;
    flex-wrap: wrap;
    width: 75%;
  }
  p{
    ${tw`sm:text-right`}
    button{
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #0963CD;
    }
  }
`;

export const ProductQty = styled.div`
  ${tw`flex justify-between`}
  p{
    letter-spacing: 0.05em;
    ${tw`mt-1 text-xs text-brown-semidark`}
    :first-child{
      background: #E1E6E7;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.09em;
      color: #888D9A;
    }
  }
`;