import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Payment from '../components/Payment';
import { useObservable } from '@libreact/use-observable';
import { selectUser$ } from '../state/user/user.service';
import { getAccessToken, isRunTime, setLocalStorageItem } from '../state/utils';
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";

const PaymentPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);
  const { navigate } = useI18next();
  const [user] = useObservable(selectUser$);
  const accessToken = getAccessToken();

  if (isRunTime() && !accessToken && !user.id) {
    setLocalStorageItem('uStoreAccessRedirectBackTo', '/payment');
    navigate('/login/');
  }

  return (
    <Layout locale={language}>
      <SEO title="Payment" />
      <Payment locale={language}/>
    </Layout>
  );
};

export default PaymentPage;
