import React from 'react';
import PropTypes from "prop-types";
import MasterCard from './../../../assets/images/mcard.png';
import { graphql, useStaticQuery } from "gatsby";

const PaymentMethod = ({ checkoutCard, chooseCheckoutCard, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiPaymentPage_en: strapiPaymentPage(locale: {eq: "en"}) {
        payment_method
        choose_or_add_new_card
      }
      strapiPaymentPage_zh: strapiPaymentPage(locale: {eq: "zh"}) {
        payment_method
        choose_or_add_new_card
      }
    }
  `);
  const translations = gql[`strapiPaymentPage_${locale}`];

  if (!!checkoutCard) {
    return (
      <div className="flex items-start">
        <div className="text-sm w-full">
          <label htmlFor="comments" className="text-xs font-medium text-gray-700">
            {translations.payment_method}
          </label>
          <p className="sm:w-7/12 flex flex-start gap-4 p-4 text-xs text-gray-500 border border-gray-300 rounded-md mt-2">
            <img src={MasterCard} alt="card"/>
            <span>{checkoutCard?.type?.toUpperCase()} ending ****{checkoutCard?.last_4_digits}</span>
          </p>
          <button onClick={(e) => {e.preventDefault(); chooseCheckoutCard()}} className="text-xs text-blue-600 mt-1.5 mb-5">
            [ {translations.choose_or_add_new_card} ]
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-start">
      <div className="text-sm w-full">
        <label htmlFor="comments" className="text-xs font-medium text-gray-700">
          {translations.payment_method}
        </label>
        <br/>
        <button onClick={(e) => {e.preventDefault(); chooseCheckoutCard()}} className="text-xs text-blue-600 mt-1.5 mb-5">
          [ {translations.choose_or_add_new_card} ]
        </button>
      </div>
    </div>
  );
};

PaymentMethod.propTypes = {
  locale: PropTypes.string,
};

PaymentMethod.defaultProps = {
  locale: 'en',
};

export default PaymentMethod;
