import React, { useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import Cards from './../../../assets/images/cards.png';
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

const mtop = { marginTop: `5px` };
const NewCard = ({ locale, formikProps }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiPaymentPage_en: strapiPaymentPage(locale: {eq: "en"}) {
        credit_card_info_label
        credit_card_account_name
        credit_card_number
        credit_card_expiry_date
        credit_card_cvv
      }
      strapiPaymentPage_zh: strapiPaymentPage(locale: {eq: "zh"}) {
        credit_card_info_label
        credit_card_account_name
        credit_card_number
        credit_card_expiry_date
        credit_card_cvv
      }
    }
  `);

  const translations = gql[`strapiPaymentPage_${locale}`];

  const [expiryDate, setExpiryDate] = useState('')
  const fixCardText = (e) => {
    let text = e.target.value;
    if (text.length > 5) {
      return;
    }
    if (text.length === 2 && expiryDate.length === 1) {
      text += '/'
    } else if (text.length === 2 && expiryDate.length === 3) {
      text = text.substring(0, text.length - 1)
    }
    setExpiryDate(text)
    formikProps.setFieldValue('expiryDate', text)
  }
  return (
    <div
      className="wrapCard px-4 py-5 bg-gray-50 border border-gray-200 space-y-6 sm:p-6 rounded-md"
      style={mtop}
    >
      <h4 className="text-base font-medium leading-6 text-gray-900">{translations.credit_card_info_label}</h4>
      <img src={Cards} alt="cards" />
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label htmlFor="cardName" className="block text-xs font-medium text-gray-700">
            {translations.credit_card_account_name}
          </label>
          <Field type="text"
                 name="cardName"
                 id="cardName"
                 autoComplete="given-name"
                 className="mt-1 block w-full text-xs border-gray-300" />
          <ErrorMessage
            name="cardName"
            component="div"
            className="mt-1 text-xs text-red-600"
          />
        </div>
      </div>

      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label htmlFor="cardNumber" className="block text-xs font-medium text-gray-700">
            {translations.credit_card_number}
          </label>
          <div id="cardNumber" style={{ height: '40px' }} />
          <div id='err_number' className="mt-1 text-xs text-red-600"></div>
        </div>
      </div>

      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-4">
          <label htmlFor="expiryDate" className="block text-xs font-medium text-gray-700">
            {translations.credit_card_expiry_date}
          </label>
          <Field
            type="text"
            name="expiryDate"
            id="expiryDate"
            className="mt-1 block w-full text-xs border-gray-300"
            value={expiryDate}
            onChange={fixCardText}
          />
          <ErrorMessage
            name="expiryDate"
            component="div"
            className="mt-1 text-xs text-red-600"
          />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <label htmlFor="CVV" className="block text-xs font-medium text-gray-700">
            {translations.credit_card_cvv}
          </label>
          <div id="CVV" className="mt-1" style={{ height: '40px' }} />
          <div id='err_cvv'></div>
        </div>
      </div>
    </div>
  );
};

NewCard.propTypes = {
  locale: PropTypes.string,
  formikProps: PropTypes.object.isRequired,
};

NewCard.defaultProps = {
  locale: 'en',
};

export default NewCard;
