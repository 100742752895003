import styled from 'styled-components';
import tw from 'twin.macro';

export const CartWrap = styled.div`
  ${tw`bg-white border-2 border-black`}
`;

export const Title = styled.p`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #9A9292;
  margin-bottom: 12px;
`;

export const FlexWrap = styled.div`
  letter-spacing: 0.05em;
  ${tw`flex flex-col bg-white`}
  .clearCart{
    margin: 10px 0px 16px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    a{
      color: #0D090E;
    }
  }
`;

export const UlWrap = styled.div`
  ${tw`flow-root`}
  ul{
    min-height: 10vh;
    max-height: 40vh;
    ${tw`overflow-y-auto`}
  }
  li{
    padding: 24px 20px;
    ${tw`flex border-b border-gray-200`}
  }
  li:nth-child(odd) {
    background: #ffffff;
  }
  li:nth-child(even) {
    background: #fafafa;
  }
`;

export const ImgWrap = styled.div`
  width: 56px;
  height: 56px;
  ${tw`flex-shrink-0 w-14 h-14 border border-gray-200 rounded-md overflow-hidden`}
  img{
    ${tw`w-14 h-14 object-center object-contain`}
  }
`;

export const WrapDetails = styled.div`
  margin-left: 12px;
  ${tw`flex-1 flex flex-col`}
`;

export const TitleBlock = styled.h3`
  line-height: 16px;
  letter-spacing: 0.04em;
  ${tw`flex justify-between text-xssemi font-medium text-brown-semidark`}
`;

export const QtyPriceBlock = styled.div`
  height:16px;
  margin-top: 8px;
  ${tw`flex-1 flex items-end justify-between text-xs`}
  button{
    vertical-align: top;
    ${tw`text-xs`}
  }
  input{
    min-width: 35px;
    max-width: 50px;
    height: 16px;
    left: 22px;
    top: 0px;
    background: #FFFFFF;
    border: 0.758558px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 1px;
    margin: 0px 6px;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.09em;
    color: #888D9A;
    text-align: center;
  }
`;

export const WrapPrice = styled.div`
  ${tw`flex`}
  p{
    ${tw`mt-1 text-xs text-brown-semidark`}
  }
`;

export const TotalBlockWrap = styled.div`
  ${tw`pt-6 px-4`}
  div{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #4D4545;
    ${tw`flex justify-between text-xssemi text-brown-semidark`}
    p:nth-child(2){
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #9B9999;
    }
    .total{
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #4D4545 !important;
    }
  }
  div:nth-child(2){
    color: #9B9999;
    ${tw`text-xs`}
  }
  div:nth-child(3){
    color: #9B9999;
    ${tw`text-xs`}
  }
`;

export const DeliveryBlock = styled.div`
  letter-spacing: 0.04em;
  margin-top: 32px;
  ${tw`pt-6 px-4 mt-1 text-xssemi text-brown-semidark`}
  p{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #4D4545;
  }
  p{${tw`m-0`}}
  p:nth-child(2){
    letter-spacing: 0.05em;
    color: #9B9999;
    ${tw`text-xs`}
  }
  button{
    ${tw`text-blue-700 m-0`}
  }
`;

export const EmptyCartBlock = styled.p`
  background: #F4F4F4;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #685858;
  ${tw`w-full inline-block align-middle text-center p-14`}
`;

export const Button = styled.button`
  width: 100%;
  background: #0D090E;
  margin: 10px 0px;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
`;
